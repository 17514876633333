<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div class="page-title" v-if="currentPage">
      <FeatherIcon :icon="currentPage.icon" size="16" />
      <p>{{ currentPage.title || currentPage.header }}</p>
    </div>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
      <bookmarks class="hide" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale class="hide" />
      <dark-Toggler class="hide" />
      <!-- class="d-none d-lg-block " -->
      <search-bar class="hide" />
      <cart-dropdown class="hide" />
      <notification-dropdown class="hide" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue"
import Bookmarks from "./components/Bookmarks.vue"
import Locale from "./components/Locale.vue"
import SearchBar from "./components/SearchBar.vue"
import DarkToggler from "./components/DarkToggler.vue"
import CartDropdown from "./components/CartDropdown.vue"
import NotificationDropdown from "./components/NotificationDropdown.vue"
import UserDropdown from "./components/UserDropdown.vue"
import { pagesHashmap } from "../../../../navigation/vertical"
export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    currentPage() {
      return pagesHashmap[this.$route.name]
    },
  },
}
</script>

<style lang="scss">
.page-title {
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
